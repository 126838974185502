import { __ } from '@wordpress/i18n';
import { Link } from 'components/Html';
import { getSiteData } from 'lib/wp/site';
import { useRouter } from 'next/router';
import { getLocale } from 'lib/i18n/utils';
import { setContentGrouping } from 'utils/seo';
import { maxAge as cacheMaxAge } from 'utils/cache';

export default function Custom404Page() {
	const router = useRouter();
	const locale = getLocale(router.locale || router.defaultLocale);

	const contentGroupingObject = {
		title: {
			rendered: 'Starbucks Stories - 404',
		},
		url: router.asPath,
		status: '404',
		type: '404',
		taxonomy: '',
		author: '',
		postDate: '',
	};
	setContentGrouping(contentGroupingObject, locale);

	return (
		<div
			className="404 page-container page-container--404 not-found"
			style={{ backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}/images/coffee-rings.png)` }}
		>
			<div className="sb-content-wrap sb-content-wrap--404 sb-section-block">
				<h1 className="not-found__title">
					{__('We\'re sorry — something has gone wrong on our end.', 'starbucks')}
				</h1>

				<h2 className="not-found__h2">{__('What could have caused this?', 'starbucks')}</h2>

				<ul>
					<li>{__('Well, something technical went wrong on our site.', 'starbucks')}</li>
					<li>
						{__(
							'We might have removed the page when we redesigned our website.',
							'starbucks',
						)}
					</li>
					<li>
						{__(
							'Or the link you clicked might be old and does not work anymore.',
							'starbucks',
						)}
					</li>
					<li>
						{__(
							'Or you might have accidentally typed the wrong URL in the address bar.',
							'starbucks',
						)}
					</li>
				</ul>

				<h2 className="not-found__h2">{__('What you can do?', 'starbucks')}</h2>

				<ul>
					<li>{__('You might try retyping the URL and trying again.', 'starbucks')}</li>
					<li>
						<Link href="/">
							{__(
								'Or we could take you back to the The Starbucks® Stories homepage.',
								'starbucks',
							)}
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}

export async function getStaticProps({ locale }) {
	try {
		const { menus, epConfig, translation } = await getSiteData({ locale });

		return {
			revalidate: cacheMaxAge,
			props: {
				menus,
				translation,
				epConfig,
			},
		};
	} catch (error) {
		return { props: {} };
	}
}
